import axios from "axios";

import { prefixApiDomain } from "../utils";

export function register({ name, surname, email, password, fiscal, store }) {
  return axios.post(prefixApiDomain("/api/register"), {
    name,
    surname,
    email,
    password,
    fiscal,
    store,
  });
}
