import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Container = styled.div`
  position: relative;
  width: 100%;
  border-top: 10px solid ${(props) => props.theme.colors.semiBlack};
  margin-top: ${(props) => props.theme.padding.unit * 3}px;
  margin-bottom: ${(props) => props.theme.padding.unit * 3}px;

  > * {
    position: absolute;
    top: -25px;

    &:nth-of-type(1) {
      left: calc(25% - 25px);
    }

    &:nth-of-type(2) {
      left: calc(50% - 25px);
    }

    &:nth-of-type(3) {
      left: calc(75% - 25px);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: ${(props) => props.theme.padding.unit * 2}px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StepText = styled.span`
  position: absolute;
  top: -40px;

  font-size: 20px;
  color: ${(props) => props.theme.colors.semiBlack};
  white-space: nowrap;
`;

export const StepButton = styled.button`
  width: 40px;
  height: 40px;
  border: 5px solid ${(props) => props.theme.colors.semiBlack};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.semiBlack};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  outline: none;
  transition: transform 0.1s linear;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const ActiveStepButton = (theme) => css`
  background-color: ${theme.colors.semiBlack};
  color: ${theme.colors.white};
  transform: scale(1.3);
`;
