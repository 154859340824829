import React from "react";
import { Link } from "gatsby";

const RegisterSuccess = () => {
  return (
    <div>
      <p>
        Se ha registrado correctamente.
        <br />
        <br />
        Su perfil será verificado y recibirá un correo cuando sea aceptado.
        <br />
        <br />
        Gracias por el interés.
      </p>
      <Link to="/">Volver al Inicio</Link>
    </div>
  );
};

export default RegisterSuccess;
