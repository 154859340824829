import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import isValidEmail from "utils/isValidEmail";

import Input from "components/Input";
import PasswordInput from "components/PasswordInput";

const labels = {
  name: "Nombre",
  surname: "Apellidos",
  email: "Dirección de correo eletrónico",
  password: "Contraseña",
  passwordConfirmation: "Contraseña (confirmación)",
};

const getInitialValues = (filledValues) => ({
  name: "",
  surname: "",
  email: "",
  password: "",
  passwordConfirmation: "",
  ...filledValues,
});

const validate = (values) => {
  let errors = {};

  // Email validation
  if (!isValidEmail(values.email)) {
    errors.email = "Email no válido";
  }

  // Password confirmation
  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = "Las contraseñas no coinciden";
  }

  // All fields required
  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      errors[key] = "Requerido";
    }
  });

  return errors;
};

const RegisterPersonalForm = ({
  filledValues,
  onChange,
  onSubmit,
  submitButton,
}) => (
  <Formik
    initialValues={getInitialValues(filledValues)}
    validate={validate}
    onSubmit={onSubmit}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
      const myHandleChange = (event) => {
        handleChange(event);
        onChange(event.target.name, event.target.value);
      };
      return (
        <form onSubmit={handleSubmit}>
          <Input
            label={labels.name}
            name="name"
            value={values.name}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.name && touched.name && errors.name}
          />
          <Input
            label={labels.surname}
            name="surname"
            value={values.surname}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.surname && touched.surname && errors.surname}
          />
          <Input
            label={labels.email}
            type="email"
            name="email"
            value={values.email}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email && errors.email}
          />
          <PasswordInput
            label={labels.password}
            name="password"
            value={values.password}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.password && touched.password && errors.password}
          />
          <PasswordInput
            label={labels.passwordConfirmation}
            name="passwordConfirmation"
            value={values.passwordConfirmation}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={
              errors.passwordConfirmation &&
              touched.passwordConfirmation &&
              errors.passwordConfirmation
            }
          />
          {submitButton}
        </form>
      );
    }}
  </Formik>
);

RegisterPersonalForm.propTypes = {
  filledValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButton: PropTypes.element.isRequired,
};

export default RegisterPersonalForm;
