import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { register } from "apiClient/auth/register";

import getUserFromValues from "utils/getUserFromValues";

import withAuth from "contexts/withAuth";

import UserLayout from "layouts/UserLayout";

import Button from "components/Button";

import RegisterPersonalForm from "pagesComponents/Register/RegisterPersonalForm";
import RegisterFiscalForm from "pagesComponents/Register/RegisterFiscalForm";
import RegisterStoreForm from "pagesComponents/Register/RegisterStoreForm";
import RegisterSuccess from "pagesComponents/Register/RegisterSuccess";
import FormError from "components/FormError";
import RegisterSteps from "pagesComponents/Register/RegisterSteps";

const CenterAligned = styled.div`
  text-align: center;
`;

const ButtonCss = (theme) => css`
  margin: ${theme.padding.unit}px 0;
`;

const STEPS_COUNT = 3;

class RegisterPage extends React.Component {
  state = {
    step: 1,
    lastStepCompleted: 0,
    submitError: "",
    values: {
      fiscalIva: "21%",
      fiscalTaxes: "5.20%",
    },
  };

  copyValueInState = (name, value) => {
    this.setState((state) => ({ values: { ...state.values, [name]: value } }));
  };

  goToStep = (step) => {
    const { lastStepCompleted } = this.state;

    if (step - 1 <= lastStepCompleted) {
      this.setState((state) => ({
        step,
        lastStep: Math.max(state.lastStep, step),
      }));
    }
  };

  completeStep = (step) => {
    this.setState({ lastStepCompleted: step }, () => {
      this.goToStep(step + 1);
    });
  };

  nextStep = () => {
    const { step } = this.state;

    if (step + 1 <= STEPS_COUNT) {
      this.completeStep(step);
    } else {
      this.registerUser();
    }
  };

  registerUser = async () => {
    const { values, step } = this.state;

    this.setState({ submitError: "" });

    try {
      await register(getUserFromValues(values));

      // TODO: set session for private selling
      // this.props.setSession({ email: values.email, token: result.data.token });

      this.completeStep(step);
    } catch (error) {
      console.error(error);
      const errorMessage = error.response.data.error;

      this.setState({ submitError: errorMessage });
    }
  };

  render() {
    const { values, step, lastStepCompleted, submitError } = this.state;

    let Content;
    switch (step) {
      case 1: {
        Content = RegisterPersonalForm;
        break;
      }
      case 2: {
        Content = RegisterFiscalForm;
        break;
      }
      case 3: {
        Content = RegisterStoreForm;
        break;
      }
      case 4: {
        Content = RegisterSuccess;
        break;
      }
    }

    const notSuccessPage = step < 4;

    return (
      <UserLayout title="Registro de usuario">
        {notSuccessPage && (
          <RegisterSteps
            activeStep={step}
            lastStepCompleted={lastStepCompleted}
            onClick={this.goToStep}
          />
        )}
        <Content
          filledValues={values}
          onChange={this.copyValueInState}
          onSubmit={this.nextStep}
          submitButton={
            <CenterAligned>
              <Button css={ButtonCss} type="submit">
                {step < STEPS_COUNT ? "Siguiente" : "Registrarse"}
              </Button>
            </CenterAligned>
          }
        />
        {submitError && <FormError>{submitError}</FormError>}
      </UserLayout>
    );
  }
}

export default withAuth(RegisterPage);
