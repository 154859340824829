import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Container = styled.div`
  display: flex;
`;

export const InputCss = css`
  display: inline-block;
`;

export const ShowButton = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.colors.grey};

  font-size: 12px;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  border: none;
`;
