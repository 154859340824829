import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import Input from "components/Input";

const labels = {
  storeName: "Nombre tienda",
  storePhone: "Teléfono tienda",
  storeAddress: "Dirección tienda",
  storePostalCode: "Código postal tienda",
  storeCity: "Ciudad tienda",
  storeFacebook: "Facebook tienda (opcional)",
  storeInstagram: "Instagram tienda (opcional)",
  storeWebsite: "Web tienda (opcional)",
};

const getInitialValues = (filledValues) => ({
  storeName: "",
  storePhone: "",
  storeAddress: "",
  storePostalCode: "",
  storeCity: "",
  storeFacebook: "",
  storeInstagram: "",
  storeWebsite: "",
  ...filledValues,
});

const validate = (values) => {
  let errors = {};

  Object.keys(values).forEach((key) => {
    // No validation
    if (
      key === "storeFacebook" ||
      key === "storeInstagram" ||
      key === "storeWebsite"
    ) {
      return;
    }

    // All other fields required
    if (!values[key]) {
      errors[key] = "Requerido";
    }
  });

  return errors;
};

const RegisterStoreForm = ({
  filledValues,
  onChange,
  onSubmit,
  submitButton,
}) => (
  <Formik
    initialValues={getInitialValues(filledValues)}
    validate={validate}
    onSubmit={onSubmit}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
      const myHandleChange = (event) => {
        handleChange(event);
        onChange(event.target.name, event.target.value);
      };
      return (
        <form onSubmit={handleSubmit}>
          <Input
            label={labels.storeName}
            name="storeName"
            value={values.storeName}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.storeName && touched.storeName && errors.storeName}
          />
          <Input
            label={labels.storePhone}
            type="tel"
            name="storePhone"
            value={values.storePhone}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.storePhone && touched.storePhone && errors.storePhone}
          />
          <Input
            label={labels.storeAddress}
            name="storeAddress"
            value={values.storeAddress}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={
              errors.storeAddress && touched.storeAddress && errors.storeAddress
            }
          />
          <Input
            label={labels.storePostalCode}
            name="storePostalCode"
            value={values.storePostalCode}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={
              errors.storePostalCode &&
              touched.storePostalCode &&
              errors.storePostalCode
            }
          />
          <Input
            label={labels.storeCity}
            name="storeCity"
            value={values.storeCity}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.storeCity && touched.storeCity && errors.storeCity}
          />
          <Input
            label={labels.storeFacebook}
            name="storeFacebook"
            value={values.storeFacebook}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={
              errors.storeFacebook &&
              touched.storeFacebook &&
              errors.storeFacebook
            }
          />
          <Input
            label={labels.storeInstagram}
            name="storeInstagram"
            value={values.storeInstagram}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={
              errors.storeInstagram &&
              touched.storeInstagram &&
              errors.storeInstagram
            }
          />
          <Input
            label={labels.storeWebsite}
            name="storeWebsite"
            value={values.storeWebsite}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={
              errors.storeWebsite && touched.storeWebsite && errors.storeWebsite
            }
          />
          {submitButton}
        </form>
      );
    }}
  </Formik>
);

RegisterStoreForm.propTypes = {
  filledValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButton: PropTypes.element.isRequired,
};

export default RegisterStoreForm;
