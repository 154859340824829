import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  StepContainer,
  StepText,
  StepButton,
  ActiveStepButton,
} from "./RegisterSteps.styles";

const RegisterSteps = ({ activeStep, onClick }) => {
  return (
    <Container>
      <Step
        isActive={activeStep === 1}
        text="Datos usuario"
        number={1}
        onClick={onClick}
      />
      <Step
        isActive={activeStep === 2}
        text="Datos personales"
        number={2}
        onClick={onClick}
      />
      <Step
        isActive={activeStep === 3}
        text="Datos tienda"
        number={3}
        onClick={onClick}
      />
    </Container>
  );
};

RegisterSteps.propTypes = {
  activeStep: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Step = ({ isActive, text, number, onClick }) => (
  <StepContainer
    onClick={(event) => {
      event.preventDefault();
      onClick(number);
    }}
  >
    {isActive && <StepText>{text}</StepText>}
    <StepButton css={isActive && ActiveStepButton}>{number}</StepButton>
  </StepContainer>
);

Step.propTypes = {
  isActive: PropTypes.bool.isRequired,
  text: PropTypes.string,
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RegisterSteps;
