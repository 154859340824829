import React from "react";

import { Container, InputCss, ShowButton } from "./PasswordInput.styles";

import Input from "components/Input";
import Label from "components/Label";
import FormError from "components/FormError";

class PasswordInput extends React.Component {
  state = {
    show: false,
  };

  toggleShow = (event) => {
    event.preventDefault();

    this.setState((state) => ({
      show: !state.show,
    }));
  };

  render() {
    const { show } = this.state;
    const { label, labelCss, error, ...rest } = this.props;
    return (
      <Label label={label} css={labelCss}>
        {(label) => (
          <>
            <Container>
              <Input
                id={label}
                type={show ? "text" : "password"}
                css={InputCss}
                {...rest}
              />
              <ShowButton onClick={this.toggleShow}>Mostrar</ShowButton>
            </Container>
            {error && <FormError>{error}</FormError>}
          </>
        )}
      </Label>
    );
  }
}

export default PasswordInput;
