import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import Input from "components/Input";
import Dropdown from "components/Dropdown";

const labels = {
  fiscalName: "Nombre fiscal",
  fiscalAddress: "Domicilio fiscal",
  fiscalCif: "CIF / NIF",
  fiscalIva: "I.V.A.",
  fiscalTaxes: "Recargo de equivalencia",
};

const getInitialValues = (filledValues) => ({
  fiscalName: "",
  fiscalAddress: "",
  fiscalCif: "",
  fiscalIva: "",
  fiscalTaxes: "",
  ...filledValues,
});

const validate = (values) => {
  let errors = {};

  // All fields required
  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      errors[key] = "Requerido";
    }
  });

  return errors;
};

const RegisterFiscalForm = ({
  filledValues,
  onChange,
  onSubmit,
  submitButton,
}) => (
  <Formik
    initialValues={getInitialValues(filledValues)}
    validate={validate}
    onSubmit={onSubmit}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
      const myHandleChange = (event) => {
        handleChange(event);
        onChange(event.target.name, event.target.value);
      };
      return (
        <form onSubmit={handleSubmit}>
          <Input
            label={labels.fiscalName}
            name="fiscalName"
            value={values.fiscalName}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.fiscalName && touched.fiscalName && errors.fiscalName}
          />
          <Input
            label={labels.fiscalAddress}
            name="fiscalAddress"
            value={values.fiscalAddress}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={
              errors.fiscalAddress &&
              touched.fiscalAddress &&
              errors.fiscalAddress
            }
          />
          <Input
            label={labels.fiscalCif}
            name="fiscalCif"
            value={values.fiscalCif}
            onChange={myHandleChange}
            onBlur={handleBlur}
            error={errors.fiscalCif && touched.fiscalCif && errors.fiscalCif}
          />
          <Dropdown
            label={labels.fiscalIva}
            name="fiscalIva"
            value={values.fiscalIva}
            onChange={myHandleChange}
            onBlur={handleBlur}
          >
            {["21%", "0%"]}
          </Dropdown>
          <Dropdown
            label={labels.fiscalTaxes}
            name="fiscalTaxes"
            value={values.fiscalTaxes}
            onChange={myHandleChange}
            onBlur={handleBlur}
          >
            {["5.20%", "0%"]}
          </Dropdown>
          {submitButton}
        </form>
      );
    }}
  </Formik>
);

RegisterFiscalForm.propTypes = {
  filledValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButton: PropTypes.element.isRequired,
};

export default RegisterFiscalForm;
